import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './styles/home.css';

import home_bg1 from './Images/home_bg1.jpg';
import home_bg2 from './Images/home_bg2.jpg';
import home_bg3 from './Images/home_bg3.jpg';

const images = [home_bg1, home_bg2, home_bg3];

const Home = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home_content">
      <div className="slider" style={{ transform: `translateX(-${currentImage * 100}%)` }}>
        {images.map((image, index) => (
          <div key={index} className="slide" style={{ backgroundImage: `url(${image})`, backgroundPosition: 'center' }} />
        ))}
      </div>
      <div className="overlay">
        <div className="home_main_content">
          <h1>DEC Gaming Studio Pvt Ltd</h1>
          <p>An Environment that is connecting Creative, Professional Experts</p>
          <Link to="/services">
            <button className="header-button btn_border">Services</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
