import React from 'react';
import './styles/Footer.css';
import logo from './Images/logo_dec.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_sub">
        <div className="footer-logo">
          <img src={logo} alt="DEC Gaming Studio Logo" className="logo" />
          <h1>Dec Gaming Studio</h1>
        </div>

        <div className='address_main'>
            <p class="address"><span class="sub_title">ADDRESS:</span> <br /> C-230, Siddharth Excellence, opp D-mart, Vasana Road, Vadodara, Gujarat 390007 India</p>
          </div>

        <div className="contact-info">
          
          <div>
          <div class="personal_contact">
            <div> <span class="sub_title">PH.NO:</span><br /><a href="tel:++917990667314">+91 79906 67314</a></div>
            <div> <span class="sub_title">E-MAIL:</span><br /><a href="mailto:hr@decgamingstudio.com">hr@decgamingstudio.com</a></div>
          </div>
          <div className="social-media">
            <a href="https://www.linkedin.com/company/dec-gaming-studio/"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="Vector" d="M0 2.14875C0 0.961875 0.98625 0 2.20312 0H27.7969C29.0138 0 30 0.961875 30 2.14875V27.8512C30 29.0381 29.0138 30 27.7969 30H2.20312C0.98625 30 0 29.0381 0 27.8512V2.14875ZM9.26812 25.1138V11.5669H4.76625V25.1138H9.26812ZM7.01813 9.71625C8.5875 9.71625 9.56437 8.6775 9.56437 7.37625C9.53625 6.04688 8.58938 5.03625 7.04813 5.03625C5.50688 5.03625 4.5 6.04875 4.5 7.37625C4.5 8.6775 5.47687 9.71625 6.98812 9.71625H7.01813ZM16.2206 25.1138V17.5481C16.2206 17.1431 16.2506 16.7381 16.3706 16.4494C16.695 15.6412 17.4356 14.8031 18.6806 14.8031C20.31 14.8031 20.9606 16.0444 20.9606 17.8669V25.1138H25.4625V17.3438C25.4625 13.1812 23.2425 11.2463 20.28 11.2463C17.8913 11.2463 16.8206 12.5588 16.2206 13.4831V13.53H16.1906C16.2006 13.5143 16.2106 13.4987 16.2206 13.4831V11.5669H11.7206C11.7769 12.8381 11.7206 25.1138 11.7206 25.1138H16.2206Z" fill="#F7B006" />
            </svg></a>
          </div>
          </div>
        </div>


      </div>
      <div className="copywrite">
        <p>© DEC Gaming Studio Pvt Ltd</p>

      </div>
    </footer>
  );
};

export default Footer;
