import React from 'react';
import './styles/Products.css';
import productimg from './Images/product_image.webp';

const Products = () => {
  return (
    <section className="products-section">
      <div className="products-header">
        <h3>Our Products</h3>
      </div>

      <div className="products-container">
        {/* Left Section: Product Image */}
        <div className="product-image">
          <img src={productimg} alt="Product" />
        </div>

        {/* Right Section: Product Information */}
        <div className="product-info">
          <h3 className="product-title">AR Animal Puzzle Card</h3>
          <p className="product-description">
          The <i><b>AR Animal Puzzle Box</b></i> combines fun and learning with 48 interactive puzzle cards that work with 
          the <i><b>Play Animal Encyclopedia With AR</b></i> Android app. Complete with a user manual, carry pouch, and 
          activation code, it offers a unique learning experience with animal rhymes, dances, and more. Trusted 
          by parents and designed by experts, it makes learning exciting and engaging for kids. Perfect for all 
          Android devices, it seamlessly blends physical play with digital education.
          </p>
          <a href="https://www.deckidz.com/" className="product-button" target="_blank" rel="noopener noreferrer">
            Explore Now
          </a>
        </div>
      </div>
    </section>
  );
};

export default Products;
