import React, { useState } from 'react';
import './styles/About.css';
import story from "./Images/our_story.jpg";
import technology from "./Images/technology.jpg";
import vision from "./Images/vision.jpeg";

const AboutUs = () => {
  const [content, setContent] = useState('story');

  const contentMap = {
    story: {
      title: 'Our Story',
      text: 'Our journey began with a deep passion for crafting exceptional gaming experiences, driven by creativity and innovation. Over the years, we have transformed into a leading provider of cutting-edge gaming solutions, continually evolving to meet the dynamic needs of our players.',
      image: story,
    },
    vision: {
      title: 'Our Vision',
      text: 'At DEC, we strive to inspire every gamer through innovative and engaging experiences. Our customer-centric approach ensures we develop tailored solutions that resonate with individual needs. By working closely with our clients, we create flexible and scalable gaming solutions that adapt to ever-changing demands, maximizing their potential for success.',
      image: vision,
    },
    technology: {
      title: 'Technology',
      text: 'We harness advanced technology to build immersive gaming platforms that deliver outstanding performance and user satisfaction. Our commitment to continuous innovation drives us to explore and integrate the latest trends, ensuring we remain at the forefront of the gaming industry while redefining the gaming experience for our users.',
      image: technology,
    },
  };

  return (
    <section className="about-us">
      <div className="overlay_about">
        <h1 className="title">About Us</h1>
        <div className="overlay_content">
          <div className="content">
            <div className="left">
              <img className='about_img' src={contentMap[content].image} alt={contentMap[content].title} loading="lazy" />
            </div>
            <div className="right">
              <h2>{contentMap[content].title}</h2>
              <p>{contentMap[content].text}</p>
            </div>
          </div>
          <div className="buttons">
            <button 
              className={`btn btn_border ${content === 'story' ? 'active' : ''}`} 
              onClick={() => setContent('story')}>
              OUR STORY
            </button>
            <button 
              className={`btn btn_border ${content === 'vision' ? 'active' : ''}`} 
              onClick={() => setContent('vision')}>
              OUR VISION
            </button>
            <button 
              className={`btn btn_border ${content === 'technology' ? 'active' : ''}`} 
              onClick={() => setContent('technology')}>
              TECHNOLOGY
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
