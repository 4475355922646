import React from 'react';
import './styles/Contact.css';

const Contact = () => {
    return (
        <div className="contact">
            <h3>Contact us</h3>
            <div className="map">
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d59065.00633249764!2d73.11283637279175!3d22.294541700565595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x395fc620c03121f7%3A0xe141965129efbfdb!2sSiddharth%20Excellence%2C%20B%20R%20Patel%20Group%2C%2075V3%2BRJ7%2C%20New%20Vasana%20Rd%2C%20Saraswati%20Society%2C%20Tandalja%2C%20Vadodara%2C%20Gujarat%20390012!3m2!1d22.2945469!2d73.1540362!5e0!3m2!1sen!2sin!4v1729224196586!5m2!1sen!2sin" 
                
                
                allowFullScreen 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            </div>
        </div>
    );
};

export default Contact;
