import React from "react";
import './styles/Services.css';
import software from "./Images/software.jpg";
import app from "./Images/app.jpg";
import arvr from "./Images/ARVR.jpg";
import qualityassure from "./Images/qualityassure.jpg";
import projectdev from './Images/projectdev.jpg';
import itconsultancy from './Images/itconsultancy.jpg';

const servicesData = [
  { image: software, title: "SOFTWARE DEVELOPMENT", description: "We focus on custom-fit, flexible solutions to address your time-sensitive requirements. We offer outsourcing solutions for any programming language you require." },
  { image: arvr, title: "AR/VR DEVELOPMENT", description: "Success relies on accessibility. We support your AR and VR development from start to finish, ensuring engaging experiences for all. Let us bring your vision to life." },
  { image: qualityassure, title: "Quality Assurance", description: "Our strategic approach to testing and quality assurance employs precise methodologies for thorough validation and preventative measures, we provide highly effective QA services." },
  { image: projectdev, title: "Project Management", description: "Our agile PMO services are designed to enhance your business, ensuring the success of critical and complex projects from inception to completion. We partner with you through every phase, from planning to final delivery." },
  { image: app, title: "APP DEVELOPMENT", description: "Success hinges on accessibility. From design to implementation, and through maintenance and updates, we support your web and mobile application development every step of the way." },
  { image: itconsultancy, title: "IT Consultancy", description: "Providing expert guidance and tailored solutions to optimize IT infrastructure, enhance cybersecurity, and drive digital transformation for businesses." },

];

const Services = () => {
  return (
    <section className="services-section">
      <h2 className="section-title">Our Services</h2>
      <div className="services">
        {servicesData.map((service, index) => (
          <div key={index} className="e-card playing">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="infotop">
          
          <img src={service.image} alt={service.image}  loading="Lazy"/>
              <h3>{service.title}</h3>
              <p className="description">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
