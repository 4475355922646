import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles/Header.css';
import logo from './Images/logo_dec.png';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close the mobile menu when a link is clicked
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="navbar">
        <div className="left_nav">
          <img src={logo} alt="DEC Gaming Studio Logo" className="logo" />
          <h1>Dec Gaming Studio</h1>
        </div>
        <nav className={isMobileMenuOpen ? "nav-open" : ""}>
          <ul>
            <li><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
            <li><Link to="/about" onClick={closeMobileMenu}>About Us</Link></li>
            <li><Link to="/services" onClick={closeMobileMenu}>Services</Link></li>
            <li><a href="/career" onClick={closeMobileMenu}>Careers</a></li>
            <li><a href="/our-products" onClick={closeMobileMenu}>Our Products</a></li>
            <li><a href="/contact" onClick={closeMobileMenu}>Contact Us</a></li>
          </ul>
        </nav>
        <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? '✖' : '☰'}
        </button>
      </div>
    </header>
  );
};

export default Header;
