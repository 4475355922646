import React from 'react';
import './styles/Career.css';

const Career = () => {
    return (

        <section className='career'>
                <div className="career_content">
                <div className="career_heading">
                <h3>Career</h3>
                <p>Join Our Dynamic Team at DEC Gaming Studio!</p>
                </div>
                <div className="career_section_content">
                <div className="career_left">
                </div>
                <div className="career_right">
                    <p>Are you passionate about innovation, creativity, and gaming? At DEC Gaming Studio, we believe in pushing the boundaries of what's possible and turning dreams into reality. Whether you're an experienced professional or a rising talent, we invite you to be part of our growing family, where every idea counts and every team member contributes to our success.</p>

                    <div className="career_why">
                        <h4>Why Work with Us?</h4>
                        <ul>
                            <li>Collaborate with industry experts and creative minds</li>
                            <li>Work on cutting-edge projects in a fast-evolving gaming world</li>
                            <li>Enjoy a vibrant work culture that values growth and creativity</li>
                        </ul>
                    </div>
                    <p>If you're ready to take the next step in your career and be part of something extraordinary, we would love to hear from you! Please send your CV to <a href="mailto:hr@decgamingstudio.com" className="career_mail" target="_blank" rel="noopener noreferrer">hr@decgamingstudio.com</a> and let's build the future of gaming together.</p>
                    <p></p>
                </div>
            </div>
            </div>
        </section>
    );
};

export default Career;